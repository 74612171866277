import { Dropdown } from "react-bootstrap";
import { MdNotifications } from "react-icons/md";

export default function Notification() {
    return (
        <Dropdown as="li" className="nav-item notification_dropdown ">
            <Dropdown.Toggle className="nav-link i-false c-pointer" variant="" as="a" data-toggle="dropdown" aria-expanded="false">
                <div style={{ position: "relative", display: "flex", flexDirection: "row" }}>
                    <MdNotifications style={{ fontSize: "1.8rem" }} />
                    {/* <span
                        style={{
                            position: "absolute",
                            top: -10,
                            right: -14,
                            fontSize: "0.7rem",
                            height: "1.5rem",
                            width: "1.5rem",
                        }}
                        className="badge light text-white bg-warning rounded-circle d-flex flex-row justify-content-center align-items-center">
                        12
                    </span> */}
                </div>
            </Dropdown.Toggle>
            {/* <Dropdown.Menu align="start" className="mt-2 dropdown-menu dropdown-menu-end">
                <PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
                    <ul className="timeline">
                        <li>
                            <div className="timeline-panel">
                                <div className="media me-2 media-danger">KG</div>
                                <div className="media-body">
                                    <h6 className="mb-1">Transaction successfully</h6>
                                    <small className="d-block">29 July 2020 - 02:26 PM</small>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-panel">
                                <div className="media me-2">
                                    <img alt="images" width={50} src={avatar} />
                                </div>
                                <div className="media-body">
                                    <h6 className="mb-1">Dr sultads create new Transaction</h6>
                                    <small className="d-block">29 July 2020 - 02:26 PM</small>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-panel">
                                <div className="media me-2 media-danger">KG</div>
                                <div className="media-body">
                                    <h6 className="mb-1">Transaction successfully</h6>
                                    <small className="d-block">29 July 2020 - 02:26 PM</small>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-panel">
                                <div className="media me-2">
                                    <img alt="" width={50} src={avatar} />
                                </div>
                                <div className="media-body">
                                    <h6 className="mb-1">Dr sultads create new transaction</h6>
                                    <small className="d-block">29 July 2020 - 02:26 PM</small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </PerfectScrollbar>
                <Link className="all-notification" to="#">
                    See all notifications <i className="ti-arrow-right" />
                </Link>
            </Dropdown.Menu> */}
        </Dropdown>
    );
}
