import { createSlice } from "@reduxjs/toolkit";
import { IBank, IBankTable } from "src/models/transactionService/bank";

export interface IBankPayload {
    payload: {
        data: IBank[];
    };
}

interface IBankState {
    banks: IBank[];
    reload: number;
}

const initialState: IBankState = {
    banks: [],
    reload: 0,
};

const stateName = "banks";

const BankSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setBanks: (state, { payload }: IBankPayload) => {
            state.banks = payload.data;
        },
        appendBank: (state, { payload }: { payload: { data: IBank } }) => {
            state.banks.push(payload.data);
        },
        updateBank: (state, { payload }: { payload: { data: IBank } }) => {
            const index = state.banks.findIndex((bank) => bank.id === payload.data.id);
            state.banks[index] = payload.data;
        },
        removeBank: (state, { payload }: { payload: { id: string } }) => {
            state.banks = state.banks.filter((bank) => bank.id !== payload.id);
        },
        resetCounties: (state) => {
            state.banks = [];
        },
        reloadState: (state) => {
            state.reload = state.reload + 1;
        },
    },
});

///// Export state values ////////
export const selectBanksState = (state: any): IBank[] => state.entities[stateName].banks;
export const selectBanksForTable = (state: any): IBankTable[] =>
    state.entities[stateName].banks.map((value: IBank) => ({
        col1: value.name,
        col2: value.short_name,
        col3: value.phone,
        col4: value.email,
        col5: value.country?.name,
        col6: value.state?.name,
        col7: value.city,
        col8: value.address,
        col9: value.local_bank_id,
        col10: value.swift_code,
        col11: value.active ? "Active" : "In active",
        col12: value,
    }));
export const selectReloadState = (state: any): number => state.entities[stateName].reload;

///// Export actions ///////
export const { setBanks, resetCounties, appendBank, updateBank, removeBank, reloadState } = BankSlice.actions;

export { stateName, BankSlice };
