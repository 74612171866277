import { lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import { RouteName } from "src/constants/routeName";
import SidebarLayout from "src/layouts/SidebarLayout";
import { selectAuthRole } from "src/store/state/auth.state";
import TransactionServiceRoutes from "./transactionService";
import UserServiceRoutes from "./userService";
const Home = SuspenseLoader(lazy(() => import("src/pages/Home")));
const Status404 = SuspenseLoader(lazy(() => import("src/pages/Status404")));
const LogoutPage = SuspenseLoader(lazy(() => import("src/pages/Auth/Logout")));

const Route = (): RouteObject[] => {
    const role = useSelector(selectAuthRole);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(
        () => {
            console.log("Route", role);
            if (location.pathname.includes("/auth/")) navigate("/");
        }, // eslint-disable-next-line
        [role]
    );
    return [
        {
            path: "",
            element: <SidebarLayout />,
            children: [
                {
                    path: RouteName.HOME,
                    element: <Home />,
                },
                ...UserServiceRoutes(role),
                ...TransactionServiceRoutes(role),
                {
                    path: "*",
                    element: <Status404 />,
                },
            ],
        },

        {
            path: "/auth/logout",
            element: <LogoutPage />,
        },
    ];
};

export default Route;
