import { createSlice } from "@reduxjs/toolkit";
import { IUser, IUserTable } from "src/models/userService/user";

export interface IUserPayload {
    payload: {
        data: IUser[];
    };
}

interface IUserState {
    users: IUser[];
    reload: number;
}

const initialState: IUserState = {
    users: [],
    reload: 0,
};

const stateName = "users";

const UserSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setUsers: (state, { payload }: IUserPayload) => {
            state.users = payload.data;
        },
        appendUser: (state, { payload }: { payload: { data: IUser } }) => {
            state.users.push(payload.data);
        },
        updateUser: (state, { payload }: { payload: { data: IUser } }) => {
            const index = state.users.findIndex((user) => user.id === payload.data.id);
            state.users[index] = payload.data;
        },
        removeUser: (state, { payload }: { payload: { id: string } }) => {
            state.users = state.users.filter((user) => user.id !== payload.id);
        },
        resetCounties: (state) => {
            state.users = [];
        },
        reloadState: (state) => {
            state.reload = state.reload + 1;
        },
    },
});

///// Export state values ////////
export const selectUsersState = (state: any): IUser[] => state.entities[stateName].users;
export const selectUsersForTable = (state: any): IUserTable[] =>
    state.entities[stateName].users.map((value: IUser) => ({
        col1: `${value.first_name} ${value.last_name}`,
        col2: value.phone,
        col3: value.email,
        col4: value.country?.name ?? "",
        col5: value.city,
        col6: value,
    }));
export const selectReloadState = (state: any): number => state.entities[stateName].reload;

///// Export actions ///////
export const { setUsers, resetCounties, appendUser, updateUser, removeUser, reloadState } = UserSlice.actions;

export { stateName, UserSlice };
