import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import { ERole } from "src/constants/enum";
import { UserServiceRouteName } from "src/constants/routeName";

const UserCountryPage = SuspenseLoader(lazy(() => import("src/pages/UserService/Country")));
const PolicyPage = SuspenseLoader(lazy(() => import("src/pages/UserService/Policy")));
const UserStatePage = SuspenseLoader(lazy(() => import("src/pages/UserService/State")));
const UserPage = SuspenseLoader(lazy(() => import("src/pages/UserService/User")));
const UserDetailPage = SuspenseLoader(lazy(() => import("src/pages/UserService/UserDetail")));

const UserServiceRoutes = (role: ERole): RouteObject[] => {
    const routes: RouteObject[] = [];
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: UserServiceRouteName.COUNTRY,
            element: <UserCountryPage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: UserServiceRouteName.POLICY,
            element: <PolicyPage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: UserServiceRouteName.STATES,
            element: <UserStatePage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin, ERole.HR, ERole.Finance, ERole.CustomerService].includes(role))
        routes.push({
            path: UserServiceRouteName.USER,
            element: <UserPage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin, ERole.HR, ERole.Finance, ERole.CustomerService].includes(role))
        routes.push({
            path: UserServiceRouteName.USER_DETAIL,
            element: <UserDetailPage />,
        });

    return routes;
};

export default UserServiceRoutes;
