import { createSlice } from '@reduxjs/toolkit';
import { ITransactionDocument } from 'src/models/transactionService/transactionDocument';

export interface ITransactionDocumentPayload {
  payload: {
    data: ITransactionDocument[];
  };
}

interface ITransactionDocumentState {
  transactionDocuments: ITransactionDocument[];
}

const initialState: ITransactionDocumentState = {
  transactionDocuments: []
};

const stateName = 'transactionDocuments';

const TransactionDocumentSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setTransactionDocuments: (state, { payload }: ITransactionDocumentPayload) => {
      state.transactionDocuments = payload.data;
    },
    appendTransactionDocument: (
      state,
      { payload }: { payload: { data: ITransactionDocument } }
    ) => {
      state.transactionDocuments.push(payload.data);
    },
    updateTransactionDocument: (
      state,
      { payload }: { payload: { data: ITransactionDocument } }
    ) => {
      const index = state.transactionDocuments.findIndex(
        (transactionDocument) => transactionDocument.id === payload.data.id
      );
      state.transactionDocuments[index] = payload.data;
    },
    removeTransactionDocument: (state, { payload }: { payload: { id: string } }) => {
      state.transactionDocuments = state.transactionDocuments.filter(
        (value) => value.id !== payload.id
      );
    },
    resetCounties: (state) => {
      state.transactionDocuments = [];
    }
  }
});

///// Export state values ////////
export const selectTransactionDocumentsState = (state: any): ITransactionDocument[] =>
  state.entities[stateName].transactionDocuments;

///// Export actions ///////
export const {
  setTransactionDocuments,
  resetCounties,
  appendTransactionDocument,
  updateTransactionDocument,
  removeTransactionDocument
} = TransactionDocumentSlice.actions;

export { stateName, TransactionDocumentSlice };
