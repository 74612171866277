import { createSlice } from "@reduxjs/toolkit";
import { ERole, EStorageKeys } from "src/constants/enum";
import { IAuth } from "src/models/auth";
import AuthStorage from "src/storage/auth";

export interface IAuthPayload {
    payload: {
        data: {
            user: IAuth;
            token: string;
            refreshToken: string;
        };
    };
}

interface IAuthState {
    isLoggedIn: boolean;
    token: string | null;
    refreshToken: string | null;
    refreshTime: number | null;
    auth: IAuth | {};
}

const initialState: IAuthState = {
    isLoggedIn: false,
    refreshToken: null,
    token: null,
    refreshTime: null,
    auth: {},
};

const stateName = "auth";

const AuthSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setAuth: (state, { payload }: IAuthPayload) => {
            const date = new Date();
            date.setMinutes(date.getMinutes() + 4);
            state.auth = payload.data.user;
            state.refreshTime = date.getTime();
            state.token = payload.data.token;
            state.refreshToken = payload.data.refreshToken;
            state.isLoggedIn = true;
            // store token, refresh token and refresh time on storage
            AuthStorage.set({
                ...payload.data.user,
                refreshToken: payload.data.refreshToken,
                token: payload.data.token,
                expireDate: date.toString(),
            });
        },
        setUser: (state, { payload }: { payload: { data: IAuth } }) => {
            state.auth = payload.data;
            AuthStorage.set({
                ...payload.data,
                refreshToken: state.refreshToken!,
                token: state.token!,
                expireDate: new Date(state.refreshTime!).toString(),
            });
        },
        reset: (state) => {
            state.refreshTime = null;
            localStorage.removeItem(EStorageKeys.refreshTime);
        },
        logOut: (state) => {
            state.isLoggedIn = false;
            state.auth = {};
            state.refreshTime = null;
            state.refreshToken = null;
            state.token = null;
            // remove data from storage
            AuthStorage.remove();
        },
    },
});

///// Export state values ////////
export const selectAuthState = (state: any): IAuth => state.entities[stateName].auth;
export const selectAuthRole = (state: any): ERole => state.entities[stateName].auth?.role ?? ERole.UnAuthenticated;
export const selectIsLoggedIn = (state: any): boolean => state.entities[stateName].isLoggedIn;

///// Export actions ///////
export const { setAuth, logOut, reset, setUser } = AuthSlice.actions;

export { stateName, AuthSlice };
