import { Suspense } from "react";
import Fallback from "src/components/Fallback";

const SuspenseLoader = (Component: any) => (props: any) =>
    (
        <Suspense fallback={<Fallback />}>
            <Component {...props} />
        </Suspense>
    );

export default SuspenseLoader;
