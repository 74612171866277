import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "src/components/ScrollToTop";
import { ThemeContext } from "src/context/ThemeContext";
import Header from "./Header";
import Sidebar from "./Sidebar";
import SidebarHeader from "./SidebarHeader";

export default function SidebarLayout() {
    const { menuToggle } = useContext(ThemeContext);
    useEffect(() => {
        // document.getElementsByTagName("body")![0].style.overflow = "hidden";
    }, []);

    return (
        <>
            <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
                <SidebarHeader />
                <Header />
                <Sidebar />
                <div className="content-body" style={{ height: "100vh", overflow: "auto" }}>
                    <div className="container-fluid">
                        <Outlet />
                    </div>
                </div>
            </div>
            <ScrollToTop />
        </>
    );
}
