import { createSlice } from '@reduxjs/toolkit';

export interface ISnackbar {
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  duration?: number;
}

export interface IPayload {
  payload: {
    data: ISnackbar;
  };
}

const stateName = 'snackbar';

const initialState = {
  open: false,
  message: '',
  type: 'error',
  duration: 5000,
  count: 0
};

const SnackbarSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    openSnackbar: (state: any, { payload }: IPayload) => {
      state.open = true;
      state.message = payload.data.message;
      state.type = payload.data.type;
      state.duration = payload.data.duration ?? 5000;
      state.count += 1;
    },
    closeSnackbar: (state: any) => {
      state.open = false;
    }
  }
});

export { stateName, SnackbarSlice };

export const { openSnackbar, closeSnackbar } = SnackbarSlice.actions;

export const selectSnackbar = (state: any) => state.entities[stateName];
