export const RouteName = {
    LOGIN: "/auth/login",
    HOME: "/",
};

export const UserServiceRouteName = {
    COUNTRY: "/user/service/countries",
    POLICY: "/user/service/policies",
    STATES: "/user/service/states",
    USER: "/user/service/users",
    USER_DETAIL: "/user/service/users/:id",
};

export const TransactionServiceRouteName = {
    BANK: "/transaction/service/banks",
    COUNTRY: "/transaction/service/countries",
    CURRENCY: "/transaction/service/currencies",
    CURRENCY_EXCHANGE: "/transaction/service/currency-exchanges",
    RECIPIENT: "/transaction/service/recipients",
    RECIPIENT_DETAIL: "/transaction/service/recipients/:id",
    SENDING_REASON: "/transaction/service/sending-reasons",
    STATES: "/transaction/service/states",
    TRANSACTION: "/transaction/service/transactions",
    TRANSACTION_DETAIL: "/transaction/service/transactions/:id",
    TRANSACTION_TYPE: "/transaction/service/transaction-types",
};
