import { createSlice } from '@reduxjs/toolkit';
import { IUserDocument } from 'src/models/userService/userDocument';

export interface IUserDocumentPayload {
  payload: {
    data: IUserDocument[];
  };
}

interface IUserDocumentState {
  userDocuments: IUserDocument[];
  reload: number;
}

const initialState: IUserDocumentState = {
  userDocuments: [],
  reload: 0
};

const stateName = 'userDocuments';

const UserDocumentSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setUserDocuments: (state, { payload }: IUserDocumentPayload) => {
      state.userDocuments = payload.data;
    },
    appendUserDocument: (
      state,
      { payload }: { payload: { data: IUserDocument } }
    ) => {
      state.userDocuments.push(payload.data);
    },
    appendUserDocuments: (
      state,
      { payload }: { payload: { data: IUserDocument[] } }
    ) => {
      state.userDocuments.push(...payload.data);
    },
    updateUserDocument: (
      state,
      { payload }: { payload: { data: IUserDocument } }
    ) => {
      const index = state.userDocuments.findIndex(
        (userDocument) => userDocument.id === payload.data.id
      );
      state.userDocuments[index] = payload.data;
    },
    removeUserDocument: (state, { payload }: { payload: { id: string } }) => {
      state.userDocuments = state.userDocuments.filter(
        (userDocument) => userDocument.id !== payload.id
      );
    },
    resetCounties: (state) => {
      state.userDocuments = [];
    },
    reloadState: (state) => {
      state.reload = state.reload + 1;
    }
  }
});

///// Export state values ////////
export const selectUserDocumentsState = (state: any): IUserDocument[] =>
  state.entities[stateName].userDocuments;
export const selectReloadState = (state: any): number =>
  state.entities[stateName].reload;

///// Export actions ///////
export const {
  setUserDocuments,
  reloadState,
  resetCounties,
  appendUserDocument,
  updateUserDocument,
  removeUserDocument,
  appendUserDocuments
} = UserDocumentSlice.actions;

export { stateName, UserDocumentSlice };
