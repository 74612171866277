import { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "src/context/ThemeContext";
import logo from "src/images/duruj.jpg";

export default function SidebarHeader() {
    const [toggle, setToggle] = useState(false);
    const { navigationHandler, openMenuToggle, background } = useContext(ThemeContext);
    return (
        <div className="nav-header">
            <Link to="/" className="brand-logo">
                {background.value === "dark" || navigationHandler !== "color_1" ? (
                    <Fragment>
                        <img
                            alt=""
                            src={logo}
                            className="logo-abbr"
                            style={{ width: "57px", height: "57px", objectFit: "contain" }}
                            width="57px"
                            height="57px"
                        />
                        <div className="brand-title">
                            <h2 className="">
                                dur<span>uj</span>
                            </h2>
                            <span className="brand-sub-title">Duruj Admin</span>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <img
                            alt=""
                            src={logo}
                            className="logo-abbr"
                            style={{ width: "57px", height: "57px", objectFit: "contain" }}
                            width="57px"
                            height="57px"
                        />
                        <div className="brand-title">
                            <h2 className="">
                                dur<span>uj</span>
                            </h2>
                            <span className="brand-sub-title">Duruj Admin</span>
                        </div>
                    </Fragment>
                )}
            </Link>

            <div
                className="nav-control"
                onClick={() => {
                    setToggle(!toggle);
                    openMenuToggle();
                }}>
                <div className={`hamburger ${toggle ? "is-active" : ""}`}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
            </div>
        </div>
    );
}
