import { createSlice } from "@reduxjs/toolkit";
import { ITransaction, ITransactionTable } from "src/models/transactionService/transaction";
import { IUser } from "src/models/userService/user";

export interface ITransactionPayload {
    payload: {
        data: ITransaction[];
    };
}

export interface ITransactionUser {
    [key: string]: IUser;
}
interface ITransactionState {
    transactions: ITransaction[];
    users: ITransactionUser;
    reload: number;
}

const initialState: ITransactionState = {
    transactions: [],
    users: {},
    reload: 0,
};

const stateName = "transactions";

const TransactionSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setTransactions: (state, { payload }: ITransactionPayload) => {
            if (state.users) {
                const temp = payload.data.map((value) => ({ ...value, user: state.users[value.user_id] }));
                state.transactions = temp;
            } else state.transactions = payload.data;
        },
        setTransactionUsers: (state, { payload }: { payload: ITransactionUser }) => {
            if (state.transactions) {
                const temp = state.transactions.map((value) => ({ ...value, user: payload[value.user_id] ?? {} }));
                state.transactions = temp;
            }
            state.users = payload;
        },
        appendTransaction: (state, { payload }: { payload: { data: ITransaction } }) => {
            state.transactions.push(payload.data);
        },
        updateTransaction: (state, { payload }: { payload: { data: ITransaction } }) => {
            const index = state.transactions.findIndex((transaction) => transaction.id === payload.data.id);
            state.transactions[index] = payload.data;
        },
        removeTransaction: (state, { payload }: { payload: { id: string } }) => {
            state.transactions = state.transactions.filter((value) => value.id !== payload.id);
        },
        resetCounties: (state) => {
            state.transactions = [];
        },
        reloadState: (state) => {
            state.reload = state.reload + 1;
        },
    },
});

///// Export state values ////////
export const selectTransactionsState = (state: any): ITransaction[] => state.entities[stateName].transactions;
export const selectTransactionUser = (state: any, id: string): IUser => state.entities[stateName].users[id] ?? {};
export const selectTransactionsForTable = (state: any): ITransactionTable[] =>
    state.entities[stateName].transactions.map((value: ITransaction) => ({
        col1: value.user ? `${value.user?.first_name ?? ""} ${value.user?.last_name ?? ""}` : "not found",
        col2: `${value.recipient?.first_name ?? ""} ${value.recipient?.last_name ?? ""}`,
        col3: value.currency_exchange?.currencyFrom?.name ?? "",
        col4: value.currency_exchange?.currencyTo?.name ?? "",
        col5: value.amount,
        col6: value.transaction_type?.name ?? "",
        col7: value,
    }));
export const selectReloadState = (state: any): number => state.entities[stateName].reload;
///// Export actions ///////
export const { reloadState, setTransactionUsers, setTransactions, resetCounties, appendTransaction, updateTransaction, removeTransaction } =
    TransactionSlice.actions;

export { stateName, TransactionSlice };
