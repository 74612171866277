import { createSlice } from "@reduxjs/toolkit";
import { ICurrencyExchange, ICurrencyExchangeTable } from "src/models/transactionService/currencyExchange";

export interface ICurrencyExchangePayload {
    payload: {
        data: ICurrencyExchange[];
    };
}

interface ICurrencyExchangeState {
    currencyExchanges: ICurrencyExchange[];
    reload: number;
}

const initialState: ICurrencyExchangeState = {
    currencyExchanges: [],
    reload: 0,
};

const stateName = "currencyExchanges";

const CurrencyExchangeSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setCurrencyExchanges: (state, { payload }: ICurrencyExchangePayload) => {
            state.currencyExchanges = payload.data;
        },
        appendCurrencyExchange: (state, { payload }: { payload: { data: ICurrencyExchange } }) => {
            state.currencyExchanges.push(payload.data);
        },
        updateCurrencyExchange: (state, { payload }: { payload: { data: ICurrencyExchange } }) => {
            const index = state.currencyExchanges.findIndex((currencyExchange) => currencyExchange.id === payload.data.id);
            state.currencyExchanges[index] = payload.data;
        },
        removeCurrencyExchange: (state, { payload }: { payload: { id: string } }) => {
            state.currencyExchanges = state.currencyExchanges.filter((value) => value.id !== payload.id);
        },
        resetCounties: (state) => {
            state.currencyExchanges = [];
        },
        reloadState: (state) => {
            state.reload = state.reload + 1;
        },
    },
});

///// Export state values ////////
export const selectCurrencyExchangesState = (state: any): ICurrencyExchange[] => state.entities[stateName].currencyExchanges;
export const selectCurrencyExchangesForTable = (state: any): ICurrencyExchangeTable[] =>
    state.entities[stateName].currencyExchanges.map((value: ICurrencyExchange) => ({
        col1: value.currencyFrom?.name ?? "",
        col2: value.currencyTo?.name ?? "",
        col3: value.exchange_fee,
        col4: value.active ? "Active" : "In active",
        col5: value,
    }));
export const selectReloadState = (state: any): number => state.entities[stateName].reload;

///// Export actions ///////
export const { reloadState, setCurrencyExchanges, resetCounties, appendCurrencyExchange, updateCurrencyExchange, removeCurrencyExchange } =
    CurrencyExchangeSlice.actions;

export { stateName, CurrencyExchangeSlice };
