import { createSlice } from "@reduxjs/toolkit";
import { ISelectOption } from "src/models/others";
import { ICurrency, ICurrencyTable } from "src/models/transactionService/currency";

export interface ICurrencyPayload {
    payload: {
        data: ICurrency[];
    };
}

interface ICurrencyState {
    currencies: ICurrency[];
    reload: number;
}

const initialState: ICurrencyState = {
    currencies: [],
    reload: 0,
};

const stateName = "currencies";

const CurrencySlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setCurrencies: (state, { payload }: ICurrencyPayload) => {
            state.currencies = payload.data;
        },
        appendCurrency: (state, { payload }: { payload: { data: ICurrency } }) => {
            state.currencies.push(payload.data);
        },
        updateCurrency: (state, { payload }: { payload: { data: ICurrency } }) => {
            const index = state.currencies.findIndex((currency) => currency.id === payload.data.id);
            state.currencies[index] = payload.data;
        },
        removeCurrency: (state, { payload }: { payload: { id: string } }) => {
            state.currencies = state.currencies.filter((currency) => currency.id !== payload.id);
        },
        resetCounties: (state) => {
            state.currencies = [];
        },
        reloadState: (state) => {
            state.reload = state.reload + 1;
        },
    },
});

///// Export state values ////////
export const selectCurrenciesState = (state: any): ICurrency[] => state.entities[stateName].currencies;
export const selectCurrenciesForOption = (state: any): ISelectOption[] =>
    state.entities[stateName].currencies.map((value: ICurrency) => ({
        value: value.id,
        label: value.name,
    }));
export const selectCurrenciesForTable = (state: any): ICurrencyTable[] =>
    state.entities[stateName].currencies.map((value: ICurrency) => ({
        col1: value.name,
        col2: value.code,
        col3: value.country?.name ?? "",
        col4: value.active ? "Active" : "In active",
        col5: value,
    }));
export const selectReloadState = (state: any): number => state.entities[stateName].reload;

///// Export actions ///////
export const { reloadState, setCurrencies, resetCounties, appendCurrency, updateCurrency, removeCurrency } = CurrencySlice.actions;

export { stateName, CurrencySlice };
