import { createSlice } from "@reduxjs/toolkit";
import { IState, IStateTable } from "src/models/userService/state";

export interface IStatePayload {
    payload: {
        data: IState[];
    };
}

interface IStateState {
    states: IState[];
    reload: number;
}

const initialState: IStateState = {
    states: [],
    reload: 0,
};

const stateName = "user_states";

const StateSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setStates: (state, { payload }: IStatePayload) => {
            state.states = payload.data;
        },
        appendState: (state, { payload }: { payload: { data: IState } }) => {
            state.states.push(payload.data);
        },
        updateState: (state, { payload }: { payload: { data: IState } }) => {
            const index = state.states.findIndex((state) => state.id === payload.data.id);
            state.states[index] = payload.data;
        },
        removeState: (state, { payload }: { payload: { id: string } }) => {
            state.states = state.states.filter((state) => state.id !== payload.id);
        },
        resetStates: (state) => {
            state.states = [];
        },
        reloadState: (state) => {
            state.reload = state.reload + 1;
        },
    },
});

///// Export state values ////////
export const selectStatesState = (state: any): IState[] => state.entities[stateName].states;
export const selectStatesForTable = (state: any): IStateTable[] =>
    state.entities[stateName].states.map((value: IState) => ({
        col1: value.name,
        col2: value.short_name,
        col3: value.code,
        col4: value.country?.name ?? "",
        col5: value.active ? "Active" : "In active",
        col6: value,
    }));
export const selectReloadState = (state: any): number => state.entities[stateName].reload;

///// Export actions ///////
export const { setStates, reloadState, resetStates, appendState, updateState, removeState } = StateSlice.actions;

export { stateName, StateSlice };
