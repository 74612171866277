import { createSlice } from "@reduxjs/toolkit";
import { IRecipient, IRecipientTable } from "src/models/transactionService/recipient";

export interface IRecipientPayload {
    payload: {
        data: IRecipient[];
    };
}

interface IRecipientState {
    recipients: IRecipient[];
    reload: number;
}

const initialState: IRecipientState = {
    recipients: [],
    reload: 0,
};

const stateName = "recipients";

const RecipientSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setRecipients: (state, { payload }: IRecipientPayload) => {
            state.recipients = payload.data;
        },
        appendRecipient: (state, { payload }: { payload: { data: IRecipient } }) => {
            state.recipients.push(payload.data);
        },
        updateRecipient: (state, { payload }: { payload: { data: IRecipient } }) => {
            const index = state.recipients.findIndex((recipient) => recipient.id === payload.data.id);
            state.recipients[index] = payload.data;
        },
        removeRecipient: (state, { payload }: { payload: { id: string } }) => {
            state.recipients = state.recipients.filter((value) => value.id !== payload.id);
        },
        resetCounties: (state) => {
            state.recipients = [];
        },
        reloadState: (state) => {
            state.reload = state.reload + 1;
        },
    },
});

///// Export state values ////////
export const selectRecipientsState = (state: any): IRecipient[] => state.entities[stateName].recipients;
export const selectRecipientsForTable = (state: any): IRecipientTable[] =>
    state.entities[stateName].recipients.map((value: IRecipient) => ({
        col1: `${value.first_name} ${value.last_name}`,
        col2: value.country?.name ?? "",
        col3: value.city,
        col4: value.address,
        col5: value.phone_number,
        col6: value,
    }));
export const selectReloadState = (state: any): number => state.entities[stateName].reload;

///// Export actions ///////
export const { reloadState, setRecipients, resetCounties, appendRecipient, updateRecipient, removeRecipient } = RecipientSlice.actions;

export { stateName, RecipientSlice };
