import { createSlice } from "@reduxjs/toolkit";
import { ITransactionType, ITransactionTypeTable } from "src/models/transactionService/transactionType";

export interface ITransactionTypePayload {
    payload: {
        data: ITransactionType[];
    };
}

interface ITransactionTypeState {
    transactionTypes: ITransactionType[];
    reload: number;
}

const initialState: ITransactionTypeState = {
    transactionTypes: [],
    reload: 0,
};

const stateName = "transactionTypes";

const TransactionTypeSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setTransactionTypes: (state, { payload }: ITransactionTypePayload) => {
            state.transactionTypes = payload.data;
        },
        appendTransactionType: (state, { payload }: { payload: { data: ITransactionType } }) => {
            state.transactionTypes.push(payload.data);
        },
        updateTransactionType: (state, { payload }: { payload: { data: ITransactionType } }) => {
            const index = state.transactionTypes.findIndex((transactionType) => transactionType.id === payload.data.id);
            state.transactionTypes[index] = payload.data;
        },
        removeTransactionType: (state, { payload }: { payload: { id: string } }) => {
            state.transactionTypes = state.transactionTypes.filter((value) => value.id !== payload.id);
        },
        resetCounties: (state) => {
            state.transactionTypes = [];
        },
        reloadState: (state) => {
            state.reload = state.reload + 1;
        },
    },
});

///// Export state values ////////
export const selectTransactionTypesState = (state: any): ITransactionType[] => state.entities[stateName].transactionTypes;
export const selectTransactionTypesForTable = (state: any): ITransactionTypeTable[] =>
    state.entities[stateName].transactionTypes.map((value: ITransactionType) => ({
        col1: value.name,
        col2: value.priority,
        col3: value.transaction_fee,
        col4: value.description,
        col5: value.active ? "Active" : "In active",
        col6: value,
    }));
export const selectReloadState = (state: any): number => state.entities[stateName].reload;

///// Export actions ///////
export const { reloadState, setTransactionTypes, resetCounties, appendTransactionType, updateTransactionType, removeTransactionType } =
    TransactionTypeSlice.actions;

export { stateName, TransactionTypeSlice };
